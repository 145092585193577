<script>

/* COMPOSANTS */
import AkFormView from "@components/layout/AkFormView";
import AkDropdown from "@components/input/AkDropdown";

/* MIXINS */
import randomRef from "@mixins/randomRef";
import utilsMixin from "@mixins/utilsMixin";
import ceiMixin from "@mixins/ceiMixin";
import dateFormatter from "@mixins/dateFormatter";
import roleMixin from "@mixins/roleMixin";
import multiLineMixin from "@mixins/multiLineMixin";

/* SERVICES */
import bilanFormationService from "@services/bilanFormationService";
import ceiService from "@services/ceiService";

export default {
  components: { AkFormView, AkDropdown },
  mixins: [randomRef, utilsMixin, ceiMixin, dateFormatter, roleMixin, multiLineMixin],
  metaInfo() {
    return {
      title: "bilan_formation.synthese",
    }
  },
  data() {
    return {
      year: 0,
      yearList: [],
      ceiList: [],
      list: [],
      localFields: ['gps_diff', 'gps_form', 'pevh_date_parution', 'pevh_date_validation', 'pevh_mode_diffusion',
        'materiel_forme', 'materiel_commentaire', 'circuit_forme', 'circuit_commentaire', 'rivh_nb', 'rivh_commentaire'],
      dbFields: ['gpsDiff', 'gpsForm', 'pevhDateParution', 'pevhDateValidation', 'pevhModeDiffusion',
        'materielForme', 'materielCommentaire', 'circuitForme', 'circuitCommentaire', 'rivhNb', 'rivhCommentaire'],
      isExportDownloading: false,
    }
  },
  mounted() {
    this.loading = true;
    this.getRef().showTotalLoader();

    let p1 = ceiService.findAllCurrent();
    p1.then(data => this.ceiList = data);

    let p2 = bilanFormationService.findSyntheseByCurrentYear();
    p2.then(data => this.list = data);

    let p3 = bilanFormationService.findAllYears();
    p3.then(data => {
      this.yearList.push({ value: 0, label: this.$t('current_year') });
      data.forEach(year => this.yearList.push({ value: year, label: year + ' - ' + (year + 1) }))
    });

    Promise.all([p1, p2, p3]).then(() => this.afterPromise());
  },
  methods: {
    initData() {
      this.initCeiListForTable(this.ceiList);
      this.list.forEach(el => { this.map[el.ceiId] = el; });
    },
    afterPromise() {
      this.loading = false;
      this.getRef().hideLoader();
      this.initData();
    },
    valueForField(cei, field) {
      
      let data = this.map[cei.id];
      if (!data) return '';
      let value = data[field];
      if (!value) return '';
      if (value instanceof Date) return this.formatDate(value);
      if (field !== 'rivhNb') value = this.getTextParsed(value);
      return value;
    },
    reload(year) {
      this.loading = true;
      this.getRef().showTotalLoader();
      let p1;
      let p2;
      if (year !== 0) {
        p1 = ceiService.findAllByYear(year);
        p1.then(data => this.ceiList = data);

        p2 = bilanFormationService.findSyntheseByYear(year);
        p2.then(data => this.list = data);
      } else {
        p1 = ceiService.findAllCurrent();
        p1.then(data => this.ceiList = data);

        p2 = bilanFormationService.findSyntheseByCurrentYear();
        p2.then(data => this.list = data);
      }

      Promise.all([p1, p2]).then(() => this.afterPromise());
    },
    textLeft(field) {
      return field.includes('Commentaire') || field === 'gpsForm' ? 'text-left' : ''
    },
    download() {
      this.isExportDownloading = true;
      let p = bilanFormationService.download(this.year);
      let promises = [p];
      p.catch(e => {
        this.getRef().hideLoader();
        this.getRef().error(this.$t("error_download_synthese"));
      });
      Promise.all(promises).then(() => {
        this.isExportDownloading = false;
      });
    }
  },
  computed: {
    map() {
      let map = {};
      this.list.forEach(el => { 
        let usedCeiId = this.displayArchiveData ? el.snapshotCeiId : el.ceiId
        map[usedCeiId] = el;
        } );
      return map;
    },
    displayArchiveData(){
      return this.year !== 0;
    }
  },
  watch: {
    year(newType) {
      this.reload(newType);
    },
  },
}
</script>

<template v-if=!roleLoading>
  <AkFormView :ref="ref" :title="$t('bilan_formation.list')" :displayGoBack=false>
    <template v-slot:action>
      <button v-if=canDownloadBilanVH() @click="download()" style="margin-right: 10px;" class="btn btn-inverse-primary" :disabled="isExportDownloading">
        <i class="fe fe-download pr-1" v-if="!isExportDownloading"/>
        <i class="pi pi-spin pi-spinner mr-1" v-if="isExportDownloading"></i>
        {{ $t('download_excel') }}
      </button>
      <RouterLink v-if=canEditFormation() :to="{ name: 'bilanFormationList' }" class="btn btn-inverse-primary">
        <i class="fe fe-settings pr-1" />
        {{ $t('manage') }}
      </RouterLink>
    </template>
    <template v-slot:form>
      <div class="row">
        <AkDropdown :label="$t('year')" v-if=canViewFormationArchived v-model="year" :options=this.yearList
          class-name="col-md-2" />
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card card-statistics">
            <div class="card-body">
              <div class="table-responsive forecast-table">
                <table style="display: block; overflow-x: auto;">
                  <thead>
                    <tr>
                      <th scope="col" rowspan="2">{{ $t('ager_label') }}</th>
                      <th scope="col" rowspan="2">{{ $t('uer_label') }}</th>
                      <th scope="col" rowspan="2">{{ $t('cei_label') }}</th>
                      <th scope="col" colspan="2">{{ $t('bilan_formation.title1') }}</th>
                      <th scope="col" colspan="3">{{ $t('bilan_formation.title2') }}</th>
                      <th scope="col" colspan="2">{{ $t('bilan_formation.title3') }}</th>
                      <th scope="col" colspan="2">{{ $t('bilan_formation.title4') }}</th>
                      <th scope="col" colspan="2">{{ $t('bilan_formation.title5') }}</th>
                    </tr>
                    <tr>
                      <th scope="col" v-for="field in localFields" :key="field">{{ $t('bilan_formation.'+ field) }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="cei in ceiList" :key="cei.id">
                      <td v-if=cei.agerSize :rowspan=cei.agerSize>{{ cei.agerLabel }}</td>
                      <td v-if=cei.uerSize :rowspan=cei.uerSize>{{ cei.uerLabel }}</td>
                      <td>{{ cei.label }}</td>
                      <td v-for="field in dbFields" :key="field" v-html="valueForField(cei, field)"
                        :class="{ 'text-left': field.includes('Commentaire') || field === 'gpsForm' }"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AkFormView>
</template>